import * as yup from 'yup';

const userNameRule = /^[a-zA-Z0-9]{3,15}$/;
const passwordRule = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()_\-\+={};:'",<.>/?`~]{8,}$/;

export const profileValidationSchema = yup.object({
  UserName: yup.string().matches(userNameRule, 'No special character. 3-15 characters.').required(),
  Email: yup.string().email().required(),
  DisplayName: yup.string().nullable(),
  Password: yup.string().matches(passwordRule, 'At least 1 lowercase, 1 uppercase letter, 1 number and 8 characters.').required(),
  Role: yup.number().nullable(),
  Avatar: yup.object().nullable(),
  CaptivatedBy: yup.string().max(175).nullable(),
  NeedToLive: yup.string().max(175).nullable(),
  GratefulFor: yup.string().max(175).nullable(),
  Facebook: yup.string().nullable(),
  Twitter: yup.string().nullable(),
  Instagram: yup.string().nullable(),
  SendEmailInvite: yup.boolean().nullable()
});