import React, { useState } from 'react';
import { Box, Container, Grid, Typography } from '@material-ui/core';
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import { Form, Formik } from 'formik';
import { getInputType } from '../../lib/utils';
import { profileValidationSchema } from '../../config/form-config/user-form';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { CreateUserAdmin } from '../../lib/graphql-command/user-command';
import { ButtonTemplate, primaryButton } from '../../components/button/button-template';
import MessageDialog from '../../components/dialog/message-dialog';
import { navigate } from 'gatsby-link';

const formList = [
  { key: 'UserName', label: 'Username' },
  { key: 'DisplayName', label: 'Display Name' },
  { key: 'Email', label: 'Email' },
  { key: 'Password', label: 'Password', type: 'auto-gen-password' },
  { key: 'Avatar', label: 'Profile Picture', type: 'file-upload' },
  {
    key: 'Role', label: 'Role', type: 'select', options: [
      { label: 'Admin', value: 1 },
      { label: 'User', value: 0 }
    ]
  },
  { key: 'CaptivatedBy', label: 'Captivate By', type: 'text-area' },
  { key: 'NeedToLive', label: 'Need to Live', type: 'text-area' },
  { key: 'GratefulFor', label: 'Grateful for', type: 'text-area' },
  {
    key: 'Facebook', label: 'Facebook', start: (
      <Box display="flex" alignItems="center">
        <FacebookIcon color="primary" />
        <Box pl={5}>https://</Box>
      </Box>
    )
  },
  {
    key: 'Twitter', label: 'Twitter', start: (
      <Box display="flex" alignItems="center">
        <TwitterIcon color="primary" />
        <Box pl={5}>https://</Box>
      </Box>
    )
  },
  {
    key: 'Instagram', label: 'Instagram', start: (
      <Box display="flex" alignItems="center">
        <InstagramIcon color="primary" />
        <Box pl={5}>https://</Box>
      </Box>
    )
  },
  { key: 'Private', label: 'Private Account', type: 'checkbox' },
  { key: 'SendEmailInvite', label: 'Send the new user email about their account', type: 'checkbox' }
];

const defaultUser = {
  UserName: '',
  DisplayName: '',
  Email: '',
  Password: '',
  Avatar: null,
  Role: 0,
  CaptivatedBy: '',
  NeedToLive: '',
  GratefulFor: '',
  Pronouns: [],
  Facebook: '',
  Twitter: '',
  Instagram: '',
  Private: false,
  SendEmailInvite: false
};

export default function NewUser() {
  const [message, setMessage] = useState('');

  const processData = (data) => {
    data.SocialLink = {
      Facebook: data.Facebook,
      Twitter: data.Twitter,
      Instagram: data.Instagram,
    };
    data.Private = data.Private ? 1 : 0;
    return data;
  };

  const handleSave = (data, setSubmitting) => {
    setSubmitting(true);
    API.graphql(graphqlOperation(CreateUserAdmin, processData(data)))
    .then(result => {
      if (result.data?.createUserAdmin?.Status === 1) setMessage('You successfully added new user’s account.');
      if (data.SendEmailInvite) Auth.forgotPassword(data.Email)
        .then(result => console.log(result))
        .catch(err => console.log(err));
      setSubmitting(false);
      setTimeout(() => {
        navigate('/users');
      }, 2000);
    })
    .catch(err => {
      setMessage(err.errors[0]?.message);
      setSubmitting(false);
    });
  };

  return (
    <Container>
      <MessageDialog {...{ message, setMessage }} />
      <Box px={10}>
        <Typography variant='h5'>New User</Typography>
      </Box>
      <Formik
        validationSchema={profileValidationSchema}
        initialValues={defaultUser}
        setFieldValue
        onSubmit={(data, { setSubmitting }) => handleSave(data, setSubmitting)}
      >
        {({ values, isSubmitting, setFieldValue }) => (
          <Form>
            {formList.map((item) => (
              <Grid container justify='center' key={item.key}>
                <Grid item xs={12} md={6}>
                  <Box py={5} px={10}>
                    {getInputType(item, values[item.key], setFieldValue)}
                  </Box>
                </Grid>
              </Grid>
            ))}
            <Grid container justify='center' alignItems='center'>
              <Grid item xs={12} md={6}>
                <Box py={5} px={10}>
                  <ButtonTemplate
                    {...primaryButton}
                    type='submit'
                    disabled={!!isSubmitting}
                  >Submit</ButtonTemplate>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Container>
  )
};